import request from '@/utils/request.js'
// 用户注册
export const registeredUser = data => {
  return request({
    method: 'POST',
    url: '/api/user/register',
    data
  })
}
// 发送短信验证码
export const sendSms = mobile => {
  return request({
    method: 'POST',
    url: '/api/sms/send',
    data: {
      mobile,
      event: 'register'
    }
  })
}
