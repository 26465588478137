import request from '@/utils/request.js'
// 用户登录
export const login = data => {
  return request({
    method: 'POST',
    url: '/api/user/login',
    data
  })
}
// 发送短信验证码
export const sendSms = mobile => {
  return request({
    method: 'POST',
    url: '/api/sms/send',
    data: {
      mobile,
      event: 'mobilelogin'
    }
  })
}
