<template>
  <div class="login">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="登录" placeholder fixed> </van-nav-bar>
    <!-- 导航栏区域 -->
    <!-- 表单区域 -->
    <van-form @submit="loginSubmit" ref="loginFromRef">
      <!-- 手机号输入框 -->
      <van-field v-model="loginForm.account" placeholder="请输入账号" type="text" name="mobile">
        <i slot="left-icon" class="iconfont icon-shoujihao"></i>
      </van-field>
      <!-- 验证码输入框 -->
      <van-field v-model="loginForm.password" placeholder="请输入密码" type="number" name="captcha">
        <i slot="left-icon" class="iconfont icon-ecurityCode"></i>
        <!-- 倒计时 -->
      </van-field>
      <van-button block class="login-btn" native-type="submit">登录</van-button>
      <!-- <van-button block class="login-btn" @click="$router.push('/Registered')">注册</van-button> -->
    </van-form>
  </div>
</template>

<script>
import { login, sendSms } from '@/api/Login/Login'
import { getItem, setItem } from '../../utils/localStorage'
export default {
  name: 'Login',
  data() {
    return {
      // 登录表单数据
      loginForm: {
        mobile: '',
        captcha: ''
      },
      // 控制倒计时组件与发送验证码按钮的显示与隐藏
      isCountDownShow: false,
      // 登录表单验证队则
      loginFormRoles: {
        // 手机
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'onBlur' },
          { pattern: /^1[3|4|5|7|8|9][0-9]{9}$/, message: '请输入正确的手机号' }
        ],
        // 验证码
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'onBlur' },
          { pattern: /^[0-9]{4}$/, message: '请输入正确的验证码(验证码为4位)' }
        ]
      }
    }
  },
  mounted() {
    // 判断是否登录
    this.determineLogin()
  },
  methods: {
    // 判断是否登录
    determineLogin() {
      if (getItem('token')) {
        this.$router.push('/index')
      }
    },
    // 发送验证码
    async sendCode() {
      // 验证手机号
      await this.$refs.loginFromRef.validate('mobile')
      sendSms(this.loginForm.mobile)
      // 显示倒计时组件  隐藏发送验证码按钮
      this.isCountDownShow = true
    },
    // 表单submit事件
    async loginSubmit() {
      this.$toast.loading({
        message: '登录中...',
        forbidClick: true, // 禁用点击背景
        duration: 0 // 持续显示
      })
      const res = await login(this.loginForm)
      if (res.code !== 1) {
        this.$toast.fail(res.msg)
      } else {
        setItem('token', res.data.userinfo.token)
        this.$toast.success('登陆成功')
        this.$router.push('/index')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.van-form {
  // 输入框
  .van-field {
    height: 90px;
    padding: 0;
    display: flex;
    align-items: center;
    .iconfont {
      font-size: 37px;
      color: #666666;
      margin: 0 40px;
    }
    .van-button {
      // 发送验证码按钮
      font-size: 22px;
      color: #7e7d7e;
      background-color: #eeedef;
      border: 0;
      margin-right: 34px;
      margin-bottom: 15px;
      width: 152px;
      height: 46px;
      line-height: 46px;
    }
    .van-count-down {
      // 倒计时组件
      font-size: 22px;
      margin-right: 34px;
      width: 152px;
      height: 46px;
      line-height: 46px;
      text-align: center;
    }
  }
  .van-field:nth-child(2)::after {
    // 第二个输入框
    border: 0;
  }
  .login-btn {
    // 登录按钮
    height: 88px;
    width: 694px;
    margin: 0 auto;
    margin-top: 63px;
    border-radius: 10px;
    background-color: #6db4fb;
    border: 0;
    font-size: 30px;
    color: #fff;
  }
}
</style>
